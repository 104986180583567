<template>
  <svg
    viewBox="0 0 196 456"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:2"
  >
    <g v-if="race === 'c'">
      <path
        d="M201.098 419.717c-4.326 3.884 71.215 38.379 76.616 35.521 1.727-.913 18.417-10.856 17.491-13.872-2.034-6.623-50.917-38.81-63.567-39.209-12.651-.4-8.528-.983-19.002 6.607-10.474 7.59-11.538 10.953-11.538 10.953Z"
        class="white-fill"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M201.098 419.717c-4.326 3.884 71.215 38.379 76.616 35.521 1.727-.913 18.417-10.856 17.491-13.872-2.034-6.623-50.917-38.81-63.567-39.209-12.651-.4-8.528-.983-19.002 6.607-10.474 7.59-11.538 10.953-11.538 10.953Z"
        class="gray-stroke"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M163.262 436.966s-14.184 7.735-17.634 11.661c-3.45 3.928 53.332 38.675 59.888 40.975 6.556 2.3 36.747-12.386 38.247-15.836 1.501-3.45-54.062-43.837-58.279-45.087-4.216-1.249-22.222 8.287-22.222 8.287Z"
        class="white-fill"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M163.262 436.966s-14.184 7.735-17.634 11.661c-3.45 3.928 53.332 38.675 59.888 40.975 6.556 2.3 36.747-12.386 38.247-15.836 1.501-3.45-54.062-43.837-58.279-45.087-4.216-1.249-22.222 8.287-22.222 8.287Z"
        class="gray-stroke"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="m262.997 471.401-52.256 30.034-88.306-52.808v8.321l88.306 52.806 104.511-60.069v-8.319l-52.255 30.035Z"
        class="white-fill"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="m262.997 471.401-52.256 30.034-88.306-52.808v8.321l88.306 52.806 104.511-60.069v-8.319l-52.255 30.035Z"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linejoin:round"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="m210.733 511.417-86.111-51.252v1.901l86.111 51.252.008.005 52.1-30.005 49.913-28.735v-1.899l-102.021 58.733Z"
        class="white-fill"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="m210.733 511.417-86.111-51.252v1.901l86.111 51.252.008.005 52.1-30.005 49.913-28.735v-1.899l-102.021 58.733Z"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linejoin:round"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="m210.741 509.929-88.306-52.808 2.39 2.869 85.916 51.432 52.256-29.571 49.865-28.998 2.39-2.868-104.511 59.944Z"
        class="gray-fill"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="m268.639 462.039-13.731 8.276c-2.047 1.233-5.136 1.06-6.866-.384v-.001c-1.731-1.446-1.471-3.64.577-4.874l13.731-8.275c2.046-1.234 5.136-1.061 6.865.384l.001.001c1.731 1.447 1.471 3.639-.577 4.873Z"
        class="gray-fill"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="m268.639 462.039-13.731 8.276c-2.047 1.233-5.136 1.06-6.866-.384v-.001c-1.731-1.446-1.471-3.64.577-4.874l13.731-8.275c2.046-1.234 5.136-1.061 6.865.384l.001.001c1.731 1.447 1.471 3.639-.577 4.873Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="m315.252 441.366-88.306-52.808-104.511 60.069"
        class="gray-stroke"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M183.548 418.144s-2.458 16.135-3.994 22.816c-1.536 6.681-4.302 6.989-2.765 11.597 1.536 4.61 12.597 13.827 15.67 17.207 3.073 3.38 6.453 6.452 9.525 4.302 0 0 1.264 1.228 3.858-.614 0 0 3.516.921 5.36-.922 0 0 3.38.178 4.609-1.448 0 0 6.452 1.754 4.916-2.854-1.537-4.609-2.766-3.164-5.531-5.73-2.765-2.566-13.519-14.7-15.055-21.538-1.537-6.838-1.49-21.586-1.49-21.586s-4.963-7.374-15.103-1.23ZM236.596 401.58s2.376 11.429 6.579 14.119c4.203 2.689 17.592 8.184 20.701 10.028 3.109 1.843 2.267 2.878 2.267 2.878s1.315.911 1.315 2.345c0 1.434-1.292 1.127-1.292 1.127s1.083.218 1.058 1.857c-.019 1.231-1.377 1.399-1.377 1.399s3.005 1.519.806 3.914c-1.065 1.158-2.617 2.621-6.325 2.006-3.708-.614-6.044-2.09-8.502-2.081-5.76.025-5.545-5.429-14.557-6.76-5.982-.884-16.695-.615-18.231-7.068-.885-3.715 2.548-17.984 2.89-24.526.201-3.848 14.127-3.808 14.668.762Z"
        style="fill:#d8d9d8;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M212.636 465.667c.463.301 1.01.456 1.441.824.44.354.715.864.943 1.368.438 1.027.688 2.116.914 3.2l-.246.046c-.184-1.085-.391-2.173-.784-3.197-.204-.504-.454-1.009-.868-1.37-.403-.374-.949-.552-1.4-.871ZM211.077 472.537a18.793 18.793 0 0 0-.426-3.137c-.239-1.025-.566-2.059-1.206-2.913.675.829 1.046 1.86 1.327 2.883.277 1.031.454 2.084.555 3.152l-.25.015ZM204.689 467.511c.727.788 1.291 1.744 1.575 2.794.15.525.182 1.079.138 1.624-.029.553-.231 1.075-.449 1.58l-.222-.116c.224-.469.437-.958.485-1.485a4.439 4.439 0 0 0-.073-1.574c-.237-1.036-.759-2.007-1.454-2.823ZM199.936 468.375c.854.658 1.578 1.508 2.028 2.506.231.497.349 1.044.38 1.594.049.556-.088 1.105-.243 1.635l-.234-.088c.164-.495.317-1.011.29-1.542a4.237 4.237 0 0 0-.308-1.551c-.401-.99-1.085-1.865-1.913-2.554ZM265.823 435.456l-5.11-1.102 5.157.856-.047.246ZM266.142 432.2l-5.206-1.13 5.253.884-.047.246ZM267.184 430.008l-5.241-1.13 5.288.884-.047.246ZM266.087 428.717l-3.96-2.131 4.072 1.908-.112.223Z"
        class="gray-fill"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M262.432 204.918h-.358c.208-.895.358 0 .358 0ZM245.473 257.113c-3.963-9.442.095-13.358.095-13.358s-6.223-35.987-5.985-44.638c.335-12.14 1.492-16.055 1.492-16.055h18.237c-.224.963-.515 6.528-.759 15.008-.413 14.444-1.859 27.158.13 41.945 1.012 7.523 3.749 7.36 5.268 12.802.254.91 1.318 6.535 1.334 7.544 1.379 4.241 1.329 5.081.465 9.376-.253 1.262-4.481-.488-4.481-6.778-2.272 3.555-2.937 10.339-6.806 11.252-9.271 2.189-7.811 0-8.99-1.358-1.854-2.135-.226-4.18.631-5.89.553-1.1-1.215 2.093-2.527 1.053-2.315-1.833 3.201-7.793 1.896-10.903Z"
        style="fill:#d8d9d8;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="m253.595 198.717 7.118-4.708s-1.405-19.53-5.334-29.989c-3.929-10.459-23.741-19.37-23.741-19.37l3.298 54.067h18.659Z"
        style="fill:#154262;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M252.475 315.771s-5.49 28.73-5.478 35.204c0 0 .464-.712-1.09 6.215 0 0-10.653 33.107-9.229 51.451 0 0-19.318 16.97-17.059 5.159 2.395-12.525 2.94-13.918.566-29.833-2.945-19.745 1.383-17.161 2.183-26.446.8-9.284-6.957-41.051-6.957-41.051"
        style="fill:#d8d9d8;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M182.784 427.019c.47-6.99.893-12.109.893-28.201 0-20.023 5.119-17.351 5.708-26.651.589-9.3-6.43-41.821-6.43-41.821 7.885-8.888 41.762-3.95 41.762-3.95s-10.252 33.381-10.093 39.853c0 0 .395 2.143-1.002 9.103 0 0-13.907 35.659-14.84 51.217 0 0-16.27 4.501-15.998.45Z"
        style="fill:#d8d9d8;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M228.055 145.054c3.453.721 6.679.13 9.21 2.589 5.097 4.952 7.41 8.192 8.982 12.817 2.246 6.614 7.875 36.158 7.123 49.488-.887 15.726 1.103 33.483-.371 45.734.018 4.63-42.15 24.337-69.322 9.167 0 0 1.695-26.219-2.113-50.277-2.478-15.656-7.761-26.699-6.715-39.096.247-2.92 1.944-9.888 4.392-12.096 8.342-7.528 17.961-18.387 26.526-18.387 7.164 0 18.11-.811 22.288.061Z"
        style="fill:#66b1dd;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M183.677 264.849c-8.469 11.234-6.205 24.858-5.911 30.029.294 5.171 5.015 37.819 5.015 37.819 25.628 19.931 41.936-4.734 41.936-4.734 28.845 2.7 27.758-12.192 27.758-12.192s-.125-4.873 2.713-17.692c4.005-18.092-1.926-45.103-1.926-45.103-13.031 10.858-43.023 24.399-69.585 11.873ZM182.538 160.88s-8.665 5.256-15.667 18.09c-4.055 7.434-5.828 33.786-5.828 33.786 12.106 2.935 20.227 2.687 32.859 0 0 0 4.26-36.525 1.014-43.75-3.247-7.224-12.378-8.126-12.378-8.126Z"
        style="fill:#154262;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M185.185 214.296s-4.502 17.479-5.866 34.723c-.682 8.628-1.632 20.798-1.632 20.798s4.895 8.712 3.636 18.342c-1.335 10.218.155 9.084-1.247 10.475-1.243 1.235-4.571-.765-3.65-4.872-.6-1.866-1.093-3.766-1.495-4.751-1.031-2.524-2.269 3.606-1.872 6.952s8.46 8.518 6.839 11.76c-.607 1.216-3.56-.044-3.56-.044s-2.511-1.984-4.133-2.44c-1.485-.418-5.084-3.457-5.084-3.457s-3.77-3.714-4.068-5.057c-2.209-9.945-1.66-10.341.921-16.439 2.76-6.522 2.65-6.366 1.769-13.905-1.257-10.756-4.194-17.835-4.639-32.278-.345-11.228-.061-21.347-.061-21.347 5.6 2.456 15.081 2.34 24.142 1.54Z"
        style="fill:#d8d9d8;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M224.224 326.314c.856-4.863 1.547-9.765 2.179-14.672a583.196 583.196 0 0 0 1.692-14.751l.007-.066.05-.039a125.818 125.818 0 0 0 5.49-4.486 65.725 65.725 0 0 0 2.611-2.396c.834-.834 1.67-1.684 2.305-2.685-.614 1.015-1.434 1.885-2.252 2.738-.83.843-1.69 1.658-2.565 2.456a124.985 124.985 0 0 1-5.406 4.608l.057-.104a582.063 582.063 0 0 1-1.344 14.8c-.517 4.928-1.093 9.852-1.837 14.761l-.987-.164Z"
        style="fill:#231f20;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="m234.589 137.975-1.558 9.445c1.766 5.223-.762 12.089-7.576 12.089-7.625 0-13.985-3.227-17.393-5.601-5.253-3.66-7.587-7.145-7.587-7.145 4.143-16.37.342-19.006-2.143-23.556-4.257-7.791 35.77 6.984 36.257 14.768Z"
        style="fill:#d3d3d3;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M238.344 133.565c2.126-7.881-37.727-27.394-42.604-21.239 1.402 1.309 3.982 18.202 8.623 29.904 3.252 8.202 6.558 5.596 11.939 8.165 3.381 1.614 14.579 3.832 16.729-.645 2.149-4.476 3.89-14.9 5.313-16.185Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M184.502 105.733c-4.348-23.916-4.64-37.703 40.489-37.711 20.837-.003 27.9-1.073 27.9 32.728 0 5.314-.055 4.019-.148 8.633-.038 1.895-.867 1.15 2.136 7.358 3.89 8.043 2.514 19.239-4.473 23.218-.885 1.625-2.155 4.714-6.281 6.006-2.84.889-3.608 2.354-9.974.275-5.879 1.154-12.242 1.264-16.729-.172-5.384-1.725-7.633-2.88-11.974-14.274-6.419.543-17.651-7.942-20.946-26.061Z"
        style="fill:#d3d3d3;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M177.464 93.094c0-2.792.813-4.688.893-6.876.113-3.092.473-5.694 1.547-8.121.808-1.825 2.943-3.88 4.119-5.416 1.112-1.453 2.556-3.523 3.966-4.663 1.813-1.464 5.015-2.423 7.033-3.498 2.608-1.389 4.625-3.195 7.471-3.961 1.974-.532 5.235-.934 7.157-1.225 2.482-.375 4.408-1.202 6.652-1.221 2.257-.018 4.998.996 6.78 1.201 2.076.24 4.469.079 6.398.63 2.396.685 4.281 2.135 6.34 2.899 2.142.794 4.07 1.09 5.711 2.135 2.841 1.808 4.719 4.501 5.736 5.828 1.047 1.364 1.903 3.179 2.579 5.003 1.383 3.725 2.017 7.482 2.017 7.482s-3.085-6.352-5.454-6.714c-1.202-.184-6.444 4.692-11.82 6.114-4.231 1.12-12.754-1.678-19.497-1.745-8.321-.083-15.746 5.527-18.383 10.769-.119.235-2.951 6.296 3.206 19.051 1.31 2.714 3.198 5.728 3.198 5.728s-1.772-1.703-4.213-2.103c-4.214-.688-9.736 6.777-3.335 13.18 0 0-6.935-4.855-12.305-15.047-.938-1.782-2.645-3.723-3.356-5.827-.594-1.762-.694-4.682-1.068-6.635-.446-2.337-1.372-4.348-1.372-6.968Z"
        style="fill:#154262;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M247.125 136.011c-1.365 1.679-1.903 2.151-3.255 2.034-1.361 1.041-2.883 1.507-3.758 1.504-.879.007-2.688-1.749-3.66-1.809.36.434.874 3.221 1.76 3.617.535.239 2.831-.664 3.901-.926 1.07-.264 2.524-.427 3.436-1.136.911-.714.997-2.307 1.576-3.284Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M229.218 114.334c-3.014.745-5.076.523-7.619 3.63 0 0 1.627.605 2.746.81 1.576.289 3.102.616 4.394.158 2.63-.932 2.463-2.078 2.463-2.078.826-.502 1.456.52 1.22-.069-.605-1.507-1.718-2.818-3.204-2.451Z"
        class="white-fill"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M225.78 117.108c0 1.389 1.088 2.339 2.429 2.122 1.342-.216 2.43-1.517 2.43-2.905 0-1.389-1.088-2.339-2.43-2.123-1.341.216-2.429 1.517-2.429 2.906Z"
        class="gray-fill"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M229.218 114.334c-3.014.745-5.076.523-7.619 3.63 0 0 1.627.605 2.746.81 1.576.289 3.102.616 4.394.158 2.63-.932 2.463-2.078 2.463-2.078.826-.502 1.456.52 1.22-.069-.605-1.507-1.718-2.818-3.204-2.451Z"
        style="fill:none;stroke:#7c7c7c;stroke-width:.28px"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M230.955 113.612c1.971 1.221 1.467 3.173 1.467 3.173s-1.155-2.88-3.704-2.435c-4.17.729-5.467 2.391-7.119 3.614l-.864-.512s6.29-6.276 10.22-3.84Z"
        class="gray-fill"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M246.112 110.317c1.504-.721 1.958-.137 3.178.118 0 0 .384 1.745-.991 3.177-1.821 1.895-4.121 1.867-4.937 1.545 0 0-1.092.621-1.003 0 .469-3.263 1.861-3.933 3.753-4.84Z"
        class="white-fill"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M248.426 112.352c0 1.286-.97 2.485-2.167 2.678-1.196.193-2.166-.694-2.166-1.98s.97-2.484 2.166-2.677c1.197-.193 2.167.693 2.167 1.979Z"
        class="gray-fill"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M246.112 110.317c1.504-.721 1.958-.137 3.178.118 0 0 .384 1.745-.991 3.177-1.821 1.895-4.121 1.867-4.937 1.545 0 0-1.092.621-1.003 0 .469-3.263 1.861-3.933 3.753-4.84Z"
        style="fill:none;stroke:#7c7c7c;stroke-width:.25px"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M243.057 111.396c-1.582 1.794-.698 3.761-.698 3.761s.7-3.559 2.935-4.468c1.319-.536 2.714-.516 3.996-.254l1.116-.992s-4.196-1.625-7.349 1.953Z"
        class="gray-fill"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M233.127 132.739c1.055 1.211 2.669 2.638 2.669 2.638s5.22-3.35 5.66-3.585l2.324.322 1.394-.899s1.951-.181 2.934.396c.542.318-.66-2.992-.78-3.162.105.149 2.214 1.258 2.204 2.585-.01 1.327.874 3.406-.882 5.383-.132.039.196-3.15-.356-3.266-.552-.115-1.169 1.289-3.816.965-.804-.099-1.672.838-2.871 1.167-5.518 1.512-4.434.935-5.396.825l-3.739 4.323c.97-3.135 1.347-5.758.655-7.692ZM195.022 121.659s-.025-3.849 2.425-3.69c1.049.069 3.608.515 4.694 3.248 1.377 3.465-1.73 2.2-1.73 3.995 0 1.796-.423 1.382 1.316 3.69 0 0-3.908-2.121-4.183-3.428-.433-2.058 4.384-1.184 1.459-4.315-1.743-1.867-3.981.5-3.981.5Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
      <path
        d="M246.848 125.232c-.866-.407-1.503 2.549-3.954 2.791-2.453.241-1.642-.402-5.125.426-5.29 1.258-3.18-3.256-3.18-3.256s-3.227 3.709 0 4.267c.525.091 6.52-.059 6.52-.059l3.27 1.633-1.525-2.132c.284-.04 2.836-1.051 3.085-1.242 1.305-1.005 1.774-2.022.909-2.428ZM219.45 111.521s4.582-1.931 6.851-2.194c2.269-.263 5.157.547 6.037.584.88.036 2.721-1.954 1.281-2.343-1.441-.39-6.428-.223-8.182.144-1.754.368-5.987 3.809-5.987 3.809ZM251.139 104.658s-2.535-.176-4.398.25c-1.865.425-3.468 1.716-4.156 1.988-.688.273-2.582-.661-1.538-1.365 1.044-.703 5.297-2.579 6.779-2.781 1.482-.202 3.313 1.908 3.313 1.908Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
        transform="translate(-121.317 -58.113)"
      />
    </g>
    <g
      v-if="race === 'b'"
      transform="translate(-121.317 -58.425)"
    >
      <path
        d="M201.098 419.716c-4.326 3.885 71.215 38.38 76.616 35.522 1.727-.913 18.417-10.855 17.491-13.872-2.034-6.623-50.917-38.811-63.567-39.209-12.651-.399-8.528-.983-19.002 6.607-10.474 7.59-11.538 10.952-11.538 10.952Z"
        class="white-fill"
      />
      <path
        d="M201.098 419.716c-4.326 3.885 71.215 38.38 76.616 35.522 1.727-.913 18.417-10.855 17.491-13.872-2.034-6.623-50.917-38.811-63.567-39.209-12.651-.399-8.528-.983-19.002 6.607-10.474 7.59-11.538 10.952-11.538 10.952Z"
        class="gray-stroke"
      />
      <path
        d="M163.262 436.966s-14.184 7.735-17.634 11.662c-3.45 3.926 53.332 38.674 59.888 40.974 6.556 2.3 36.747-12.386 38.247-15.836 1.501-3.45-54.062-43.837-58.279-45.087-4.216-1.25-22.222 8.287-22.222 8.287Z"
        class="white-fill"
      />
      <path
        d="M163.262 436.966s-14.184 7.735-17.634 11.662c-3.45 3.926 53.332 38.674 59.888 40.974 6.556 2.3 36.747-12.386 38.247-15.836 1.501-3.45-54.062-43.837-58.279-45.087-4.216-1.25-22.222 8.287-22.222 8.287Z"
        class="gray-stroke"
      />
      <path
        d="m262.997 471.4-52.256 30.035-88.306-52.807v8.319l88.306 52.808 104.511-60.07v-8.319L262.997 471.4Z"
        class="white-fill"
      />
      <path
        d="m262.997 471.4-52.256 30.035-88.306-52.807v8.319l88.306 52.808 104.511-60.07v-8.319L262.997 471.4Z"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linejoin:round"
      />
      <path
        d="m210.733 511.418-86.111-51.252v1.899l86.111 51.253.008.005 52.1-30.005 49.913-28.734v-1.901l-102.021 58.735Z"
        class="white-fill"
      />
      <path
        d="m210.733 511.418-86.111-51.252v1.899l86.111 51.253.008.005 52.1-30.005 49.913-28.734v-1.901l-102.021 58.735Z"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linejoin:round"
      />
      <path
        d="m210.741 509.929-88.306-52.807 2.39 2.868 85.916 51.433 52.256-29.572 49.865-28.999 2.39-2.867-104.511 59.944Z"
        class="gray-fill"
      />
      <path
        d="m268.639 462.04-13.731 8.275c-2.047 1.234-5.136 1.061-6.866-.385v-.001c-1.731-1.445-1.471-3.638.577-4.873l13.731-8.275c2.046-1.233 5.136-1.061 6.865.385l.001.001c1.731 1.445 1.471 3.638-.577 4.873Z"
        class="gray-fill"
      />
      <path
        d="m268.639 462.04-13.731 8.275c-2.047 1.234-5.136 1.061-6.866-.385v-.001c-1.731-1.445-1.471-3.638.577-4.873l13.731-8.275c2.046-1.233 5.136-1.061 6.865.385l.001.001c1.731 1.445 1.471 3.638-.577 4.873Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
      />
      <path
        d="m315.252 441.366-88.306-52.808-104.511 60.07"
        class="gray-stroke"
      />
      <path
        d="M183.548 418.144s-2.458 16.135-3.994 22.816c-1.536 6.681-4.302 6.989-2.765 11.597 1.536 4.61 12.597 13.827 15.67 17.207 3.073 3.38 6.453 6.452 9.525 4.301 0 0 1.264 1.23 3.858-.614 0 0 3.516.922 5.36-.922 0 0 3.38.178 4.609-1.447 0 0 6.452 1.755 4.916-2.855-1.537-4.608-2.766-3.163-5.531-5.729-2.765-2.567-13.519-14.7-15.055-21.538-1.537-6.838-1.49-21.587-1.49-21.587s-4.963-7.374-15.103-1.229ZM236.596 401.579s2.376 11.43 6.579 14.12c4.203 2.689 17.592 8.183 20.701 10.027 3.109 1.844 2.267 2.879 2.267 2.879s1.315.911 1.315 2.345c0 1.433-1.292 1.127-1.292 1.127s1.083.219 1.058 1.857c-.019 1.231-1.377 1.399-1.377 1.399s3.005 1.519.806 3.913c-1.065 1.159-2.617 2.622-6.325 2.008-3.708-.616-6.044-2.091-8.502-2.082-5.76.024-5.545-5.429-14.557-6.76-5.982-.884-16.695-.615-18.231-7.068-.885-3.714 2.548-17.984 2.89-24.526.201-3.848 14.127-3.808 14.668.761Z"
        style="fill:#d8d9d8;fill-rule:nonzero"
      />
      <path
        d="M212.636 465.667c.463.301 1.01.455 1.441.823.44.355.715.865.943 1.369.438 1.027.688 2.116.914 3.2l-.246.045c-.184-1.084-.391-2.173-.784-3.197-.204-.503-.454-1.009-.868-1.369-.403-.374-.949-.553-1.4-.871ZM211.077 472.537a18.819 18.819 0 0 0-.426-3.138c-.239-1.024-.566-2.058-1.206-2.913.675.83 1.046 1.86 1.327 2.883.277 1.032.454 2.085.555 3.153l-.25.015ZM204.689 467.51c.727.789 1.291 1.745 1.575 2.795.15.524.182 1.078.138 1.624-.029.553-.231 1.076-.449 1.579l-.222-.115c.224-.469.437-.958.485-1.485a4.432 4.432 0 0 0-.073-1.573c-.237-1.037-.759-2.008-1.454-2.825ZM199.936 468.375c.854.658 1.578 1.507 2.028 2.505.231.498.349 1.045.38 1.595.049.556-.088 1.104-.243 1.634l-.234-.087c.164-.496.317-1.011.29-1.542a4.237 4.237 0 0 0-.308-1.551c-.401-.99-1.085-1.865-1.913-2.554ZM265.823 435.456l-5.11-1.103 5.157.857-.047.246ZM266.142 432.199l-5.206-1.13 5.253.885-.047.245ZM267.184 430.007l-5.241-1.129 5.288.884-.047.245ZM266.087 428.716l-3.96-2.129 4.072 1.907-.112.222Z"
        class="gray-fill"
      />
      <path
        d="M262.432 204.919h-.358c.208-.896.358 0 .358 0ZM245.473 257.113c-3.963-9.442.095-13.358.095-13.358s-6.223-35.987-5.985-44.639c.335-12.139 1.492-16.054 1.492-16.054h18.237c-.224.963-.515 6.527-.759 15.007-.413 14.445-1.859 27.159.13 41.946 1.012 7.522 3.749 7.36 5.268 12.802.254.91 1.318 6.534 1.334 7.544 1.379 4.241 1.329 5.08.465 9.376-.253 1.262-4.481-.488-4.481-6.777-2.272 3.553-2.937 10.338-6.806 11.252-9.271 2.188-7.811 0-8.99-1.359-1.854-2.136-.226-4.18.631-5.889.553-1.101-1.215 2.091-2.527 1.052-2.315-1.833 3.201-7.793 1.896-10.903Z"
        style="fill:#d8d9d8;fill-rule:nonzero"
      />
      <path
        d="m253.595 198.717 7.118-4.708s-1.405-19.53-5.334-29.989c-3.929-10.459-20.443-18.513-20.443-18.513v53.21h18.659Z"
        style="fill:#154262;fill-rule:nonzero"
      />
      <path
        d="M252.475 315.771s-5.49 28.731-5.478 35.204c0 0 .464-.713-1.09 6.214 0 0-10.653 33.108-9.229 51.452 0 0-19.318 16.969-17.059 5.159 2.395-12.525 2.94-13.918.566-29.834-2.945-19.745 1.383-17.161 2.183-26.445.8-9.284-6.957-41.051-6.957-41.051"
        style="fill:#d8d9d8;fill-rule:nonzero"
      />
      <path
        d="M182.784 427.019c.47-6.99.893-12.11.893-28.202 0-20.021 5.119-17.351 5.708-26.65.589-9.301-6.43-41.821-6.43-41.821 7.885-8.888 41.762-3.95 41.762-3.95s-10.252 33.381-10.093 39.852c0 0 .395 2.143-1.002 9.104 0 0-13.907 35.658-14.84 51.217 0 0-16.27 4.5-15.998.45Z"
        style="fill:#d8d9d8;fill-rule:nonzero"
      />
      <path
        d="M228.055 145.054c3.453.721 6.679.13 9.21 2.588 5.097 4.953 7.41 8.194 8.982 12.819 2.246 6.612 7.875 36.156 7.123 49.486-.887 15.727 1.103 33.484-.371 45.735.018 4.63-42.15 24.336-69.322 9.166 0 0 1.695-26.218-2.113-50.276-2.478-15.657-7.761-26.699-6.715-39.096.247-2.92 1.944-9.887 4.392-12.096 8.342-7.528 17.961-18.388 26.526-18.388 7.164 0 18.11-.81 22.288.062Z"
        style="fill:#66b1dd;fill-rule:nonzero"
      />
      <path
        d="M183.677 264.848c-8.469 11.235-6.205 24.859-5.911 30.03.294 5.171 5.015 37.818 5.015 37.818 25.628 19.933 41.936-4.732 41.936-4.732 28.845 2.699 27.758-12.193 27.758-12.193s-.125-4.873 2.713-17.692c4.005-18.092-1.926-45.104-1.926-45.104-13.031 10.859-43.023 24.4-69.585 11.873ZM182.538 160.88s-8.665 5.256-15.667 18.09c-4.055 7.433-5.828 33.786-5.828 33.786 12.106 2.935 20.227 2.687 32.859 0 0 0 4.26-36.525 1.014-43.75-3.247-7.225-12.378-8.126-12.378-8.126Z"
        style="fill:#154262;fill-rule:nonzero"
      />
      <path
        d="M185.185 214.296s-4.502 17.479-5.866 34.723c-.682 8.628-1.632 20.798-1.632 20.798s4.895 8.712 3.636 18.341c-1.335 10.219.155 9.085-1.247 10.476-1.243 1.235-4.571-.765-3.65-4.873-.6-1.866-1.093-3.765-1.495-4.75-1.031-2.524-2.269 3.606-1.872 6.952s8.46 8.518 6.839 11.76c-.607 1.216-3.56-.044-3.56-.044s-2.511-1.984-4.133-2.44c-1.485-.418-5.084-3.458-5.084-3.458s-3.77-3.714-4.068-5.056c-2.209-9.945-1.66-10.341.921-16.439 2.76-6.522 2.65-6.365 1.769-13.905-1.257-10.755-4.194-17.834-4.639-32.278-.345-11.229-.061-21.347-.061-21.347 5.6 2.457 15.081 2.34 24.142 1.54Z"
        style="fill:#d8d9d8;fill-rule:nonzero"
      />
      <path
        d="M224.224 326.314c.856-4.864 1.547-9.765 2.179-14.672a583.724 583.724 0 0 0 1.692-14.751l.007-.066.05-.039a125.818 125.818 0 0 0 5.49-4.486 64.998 64.998 0 0 0 2.611-2.397c.834-.833 1.67-1.683 2.305-2.684-.614 1.015-1.434 1.884-2.252 2.737-.83.844-1.69 1.658-2.565 2.456a123.61 123.61 0 0 1-5.406 4.609l.057-.104a582.48 582.48 0 0 1-1.344 14.8c-.517 4.928-1.093 9.851-1.837 14.761l-.987-.164Z"
        style="fill:#231f20;fill-rule:nonzero"
      />
      <path
        d="m234.212 137.392-1.558 9.446c1.767 5.222-.761 12.088-7.575 12.088-7.625 0-13.985-3.227-17.393-5.602-5.253-3.66-7.588-7.144-7.588-7.144 4.144-16.37.343-19.006-2.142-23.556-4.257-7.792 35.77 6.984 36.256 14.768Z"
        style="fill:#d3d3d3;fill-rule:nonzero"
      />
      <clipPath id="a">
        <path
          d="m234.212 137.392-1.558 9.446c1.767 5.222-.761 12.088-7.575 12.088-7.625 0-13.985-3.227-17.393-5.602-5.253-3.66-7.588-7.144-7.588-7.144 4.144-16.37.343-19.006-2.142-23.556-4.257-7.792 35.77 6.984 36.256 14.768Z"
          clip-rule="nonzero"
        />
      </clipPath>
      <g clip-path="url(#a)">
        <path
          d="M237.968 132.982c2.126-7.881-37.727-27.393-42.604-21.239 1.402 1.309 3.982 18.201 8.622 29.905 3.252 8.201 6.559 5.595 11.94 8.164 3.381 1.614 14.579 3.832 16.728-.644 2.15-4.477 3.891-14.901 5.314-16.186Z"
          style="fill:#b4b4b4;fill-rule:nonzero"
        />
      </g>
      <path
        d="M184.126 105.15c-4.348-23.916-4.64-37.702 40.489-37.71 20.837-.004 27.899-1.074 27.899 32.726 0 5.315-.055 4.02-.147 8.634-.038 1.895-.267.893 2.135 7.358 2 5.379 2.385 16.291-4.601 20.271-.885 1.626-4.507 8.021-8.634 9.313-2.839.889-7.055 1.536-13.42-.542-2.192.538-6.315 1.722-10.802.285-5.384-1.725-7.633-2.879-11.974-14.273-6.419.542-17.65-7.943-20.945-26.062Z"
        style="fill:#d3d3d3;fill-rule:nonzero"
      />
      <path
        d="M177.944 93.048c0-32.357 31.018-35.897 44.761-34.316 11.982 1.377 21.559 8.069 24.186 11.491 3.188 4.152 4.596 12.485 4.596 12.485s-3.086-6.351-5.454-6.714c-1.202-.184-6.445 4.693-11.821 6.114-4.23 1.12-12.753-1.678-19.496-1.745-8.322-.083-15.747 5.527-18.383 10.768-.119.236-2.951 6.297 3.206 19.052 1.31 2.714 3.198 5.728 3.198 5.728s-1.772-1.704-4.214-2.103c-4.213-.688-9.373 6.866-2.971 13.268 0 0-17.608-11.644-17.608-34.028Z"
        style="fill:#154262;fill-rule:nonzero"
      />
      <path
        d="M246.601 134.964c-1.364 1.679-2.201 1.887-3.553 1.77-1.361 1.042-3.563 1.55-4.437 1.547-.88.007-1.046-1.38-2.019-1.441.361.434.875 2.458 1.761 2.854.535.239 2.993-1.409 4.063-1.671 1.07-.264 2.361.318 3.274-.39.911-.715.332-1.692.911-2.669Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
      />
      <path
        d="M228.875 114.285c-2.488-.13-3.178-.332-6.94 1.875 0 0 1.418.844 2.454 1.315 1.372.623 2.091.939 3.458.838 1.944-.144 2.979-2.042 2.979-2.042.826-.502 1.456.52 1.219-.07-.605-1.507-1.641-1.836-3.17-1.916Z"
        class="white-fill"
      />
      <clipPath id="b">
        <path
          d="M228.875 114.285c-2.488-.13-3.178-.332-6.94 1.875 0 0 1.418.844 2.454 1.315 1.372.623 2.091.939 3.458.838 1.944-.144 2.979-2.042 2.979-2.042.826-.502 1.456.52 1.219-.07-.605-1.507-1.641-1.836-3.17-1.916Z"
          clip-rule="nonzero"
        />
      </clipPath>
      <g clip-path="url(#b)">
        <path
          d="M225.417 116.88c0 1.389 1.088 2.339 2.43 2.123 1.342-.216 2.43-1.517 2.43-2.906 0-1.388-1.088-2.339-2.43-2.122-1.342.216-2.43 1.517-2.43 2.905Z"
          class="gray-fill"
        />
      </g>
      <path
        d="M228.875 114.285c-2.488-.13-3.178-.332-6.94 1.875 0 0 1.418.844 2.454 1.315 1.372.623 2.091.939 3.458.838 1.944-.144 2.979-2.042 2.979-2.042.826-.502 1.456.52 1.219-.07-.605-1.507-1.641-1.836-3.17-1.916Z"
        style="fill:none;stroke:#7c7c7c;stroke-width:.28px"
      />
      <path
        d="M229.928 113.637c1.97 1.222 2.117 2.564 2.117 2.564s-2.117-2.564-4.198-2.085c-3.182.733-4.26.82-5.912 2.044l-.712-.731s4.775-4.228 8.705-1.792Z"
        class="gray-fill"
      />
      <path
        d="M245.736 109.734c1.504-.721 2.179-.645 3.733-.528 0 0-.085 1.909-.992 3.674-.676 1.316-4.065 1.662-4.881 1.34-.663-.262-1.702.975-1.613.353.469-3.262 1.861-3.932 3.753-4.839Z"
        class="white-fill"
      />
      <clipPath id="c">
        <path
          d="M245.736 109.734c1.504-.721 2.179-.645 3.733-.528 0 0-.085 1.909-.992 3.674-.676 1.316-4.065 1.662-4.881 1.34-.663-.262-1.702.975-1.613.353.469-3.262 1.861-3.932 3.753-4.839Z"
          clip-rule="nonzero"
        />
      </clipPath>
      <g clip-path="url(#c)">
        <path
          d="M248.108 112.13c0 1.286-.97 2.484-2.167 2.677-1.196.193-2.166-.693-2.166-1.979s.97-2.485 2.166-2.678c1.197-.193 2.167.693 2.167 1.98Z"
          class="gray-fill"
        />
      </g>
      <path
        d="M245.736 109.734c1.504-.721 2.179-.645 3.733-.528 0 0-.085 1.909-.992 3.674-.676 1.316-4.065 1.662-4.881 1.34-.663-.262-1.702.975-1.613.353.469-3.262 1.861-3.932 3.753-4.839Z"
        style="fill:none;stroke:#7c7c7c;stroke-width:.25px"
      />
      <path
        d="M243.189 110.824c-1.582 1.795-1.206 3.749-1.206 3.749s1.27-3.285 3.469-4.279c2.235-1.01 2.735-1.35 4.017-1.088l1.294-.997s-4.421-.962-7.574 2.615Z"
        class="gray-fill"
      />
      <path
        d="M233.551 132.135c1.054 1.211 2.669 2.639 2.669 2.639s4.921-2.185 5.361-2.418l1.709.102 1.482-.939s.607.098 1.903.067c.628-.015.221-3.117.101-3.288.106.15 1.799.43 1.789 1.757-.01 1.327-.883 3.32-1.525 5.158-.132.037.187-2.534-.365-2.65-.551-.116-1.365.771-1.774.949-.742.324-1.671.838-2.87 1.167-5.518 1.513-5.396.826-6.357.715l-4.049 4.434c.971-3.135 2.618-5.759 1.926-7.693ZM194.646 121.075s-.025-3.848 2.425-3.689c1.049.068 3.607.515 4.693 3.248 1.377 3.465-1.729 2.199-1.729 3.996 0 1.795-.423 1.381 1.316 3.689 0 0-3.908-2.122-4.183-3.428-.434-2.059 4.383-1.185 1.458-4.316-1.743-1.866-3.98.5-3.98.5Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
      />
      <path
        d="m245.723 124.988-2.89.204c-1.081-.124-1.842.635-2.84 1.268-1.633 1.036-2.234 2.115-3.414 1.859-1.538-.334-1.135-4.119-1.135-4.119s-.865 3.565-.024 4.653c.326.422 1.399 1.675 3.762.53 1.015-.492.808.202 1.529-.012l2.693 2.16-.927-3.212c.285-.04 1.879-1.271 2.136-1.453 1.11-.79 1.11-1.878 1.11-1.878ZM219.073 110.938s4.582-1.931 6.852-2.194c2.269-.263 5.157.547 6.037.583.88.037 2.72-1.953 1.28-2.343-1.44-.389-6.428-.222-8.181.145-1.754.367-5.988 3.809-5.988 3.809ZM251.843 104.406s-3.114-.23-4.979.196c-1.864.425-3.467 1.716-4.156 1.989-.687.273-2.581-.662-1.537-1.366 1.043-.703 5.297-2.579 6.779-2.781 1.481-.201 3.893 1.962 3.893 1.962Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
      />
      <path
        d="M210.048 81.365s.914 3.842 1.406 10.848c0 0 2.353-7.103 1.485-10.606 0 0 1.914 6.264 3.2 10.24 0 0 1.702-13.61-.707-12.9-2.327.686-4.088.782-11.497 2.635 0 0-3.358 3.82-1.819 7.897 0 0 1.123-4.238 2.781-6.602 0 0 2.296 6.545 2.218 9.874 0 0 .599-10.186 0-12.888 0 0 1.678 4.129 1.754 6.932l1.179-5.43Z"
        style="fill:#154262;fill-rule:nonzero"
      />
      <path
        d="M219.023 81.365s2.942 4.379 3.434 11.386c0 0 1.308-7.641.44-11.144 0 0 1.541 5.417 2.827 9.392 0 0 .394-3.255.172-6.144-.045-.594 1.988 6.166 1.988 5.624.003-5.142-2.456-9.018-2.694-9.465-1.149-2.155-6.327-3.003-13.736-1.151 0 0 .472 2.903 0 7.107 0 0 1.768-2.978 1.862-5.78 0 0 2.852 8.232 2.774 11.561 0 0 .599-10.186 0-12.888 0 0 1.678 4.129 1.753 6.932l1.18-5.43Z"
        style="fill:#154262;fill-rule:nonzero"
      />
      <path
        d="M232.608 81.019s1.992 3.65 4.484 10.482c0 0 .242-7.353-1.608-10.676 0 0 3.66 5.888 6.051 9.614 0 0-2.26-13.667-4.394-12.608-2.063 1.024-5.653-.094-12.315 2.84 0 0 2.307 5.594 3.058 9.808 0 0-.154-6.003-.868-8.779 0 0 5.137 7.687 6.017 10.979 0 0-2.347-10.128-3.705-12.701 0 0 2.816 3.818 3.695 6.569l-.415-5.528Z"
        style="fill:#154262;fill-rule:nonzero"
      />
      <path
        d="M240.522 79.182s3.939 2.274 5.793 8.549c0 0-.474-8.483-3.107-11.228 0 0 4.036 3.645 4.237 9.434 0 0 1.909-5.291.182-7.748-.354-.504 3.174 5.967 3.164 5.424-.109-6.291-3.672-8.089-4.123-8.389-2.174-1.448-8.996-.308-14.694 4.22 0 0 1.959 2.372 3.755 6.257 0 0 .02-3.313-1.376-5.817 0 0 5.495 7.594 7.182 10.555 0 0-3.416-10.664-5.383-12.808 0 0 3.694 2.979 5.243 5.416l-.873-3.865Z"
        style="fill:#154262;fill-rule:nonzero"
      />
    </g>
    <g
      v-else
      transform="translate(-121.317 -58.697)"
    >
      <path
        d="M201.098 419.717c-4.326 3.884 71.215 38.38 76.616 35.521 1.727-.913 18.417-10.855 17.491-13.872-2.034-6.624-50.917-38.81-63.567-39.21-12.651-.399-8.528-.982-19.002 6.608-10.474 7.59-11.538 10.953-11.538 10.953Z"
        class="white-fill"
      />
      <path
        d="M201.098 419.717c-4.326 3.884 71.215 38.38 76.616 35.521 1.727-.913 18.417-10.855 17.491-13.872-2.034-6.624-50.917-38.81-63.567-39.21-12.651-.399-8.528-.982-19.002 6.608-10.474 7.59-11.538 10.953-11.538 10.953Z"
        class="gray-stroke"
      />
      <path
        d="M163.262 436.966s-14.184 7.735-17.634 11.662c-3.45 3.927 53.332 38.674 59.888 40.974 6.556 2.3 36.747-12.386 38.247-15.836 1.501-3.45-54.062-43.837-58.279-45.087-4.216-1.249-22.222 8.287-22.222 8.287Z"
        class="white-fill"
      />
      <path
        d="M163.262 436.966s-14.184 7.735-17.634 11.662c-3.45 3.927 53.332 38.674 59.888 40.974 6.556 2.3 36.747-12.386 38.247-15.836 1.501-3.45-54.062-43.837-58.279-45.087-4.216-1.249-22.222 8.287-22.222 8.287Z"
        class="gray-stroke"
      />
      <path
        d="m262.997 471.4-52.256 30.035-88.306-52.807v8.319l88.306 52.808 104.511-60.07v-8.319L262.997 471.4Z"
        class="white-fill"
      />
      <path
        d="m262.997 471.4-52.256 30.035-88.306-52.807v8.319l88.306 52.808 104.511-60.07v-8.319L262.997 471.4Z"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linejoin:round"
      />
      <path
        d="m210.733 511.417-86.111-51.252v1.9l86.111 51.253.008.005 52.1-30.005 49.913-28.735v-1.899l-102.021 58.733Z"
        class="white-fill"
      />
      <path
        d="m210.733 511.417-86.111-51.252v1.9l86.111 51.253.008.005 52.1-30.005 49.913-28.735v-1.899l-102.021 58.733Z"
        style="fill:none;stroke:#7c7c7c;stroke-width:1px;stroke-linejoin:round"
      />
      <path
        d="m210.741 509.929-88.306-52.807 2.39 2.868 85.916 51.432 52.256-29.571 49.865-28.998 2.39-2.868-104.511 59.944Z"
        class="gray-fill"
      />
      <path
        d="m268.639 462.04-13.731 8.275c-2.047 1.234-5.136 1.06-6.866-.384v-.001c-1.731-1.446-1.471-3.64.577-4.874l13.731-8.275c2.046-1.234 5.136-1.061 6.865.384l.001.001c1.731 1.446 1.471 3.639-.577 4.874Z"
        class="gray-fill"
      />
      <path
        d="m268.639 462.04-13.731 8.275c-2.047 1.234-5.136 1.06-6.866-.384v-.001c-1.731-1.446-1.471-3.64.577-4.874l13.731-8.275c2.046-1.234 5.136-1.061 6.865.384l.001.001c1.731 1.446 1.471 3.639-.577 4.874Z"
        style="fill:none;stroke:#fff;stroke-width:1px"
      />
      <path
        d="m315.252 441.366-88.306-52.808-104.511 60.07"
        class="gray-stroke"
      />
      <path
        d="M183.548 418.144s-2.458 16.135-3.994 22.816c-1.536 6.681-4.302 6.988-2.765 11.597 1.536 4.609 12.597 13.827 15.67 17.207 3.073 3.38 6.453 6.452 9.525 4.301 0 0 1.264 1.229 3.858-.614 0 0 3.516.922 5.36-.922 0 0 3.38.178 4.609-1.447 0 0 6.452 1.754 4.916-2.855-1.537-4.608-2.766-3.163-5.531-5.729-2.765-2.566-13.519-14.7-15.055-21.538-1.537-6.838-1.49-21.587-1.49-21.587s-4.963-7.374-15.103-1.229ZM236.596 401.579s2.376 11.43 6.579 14.12c4.203 2.689 17.592 8.184 20.701 10.028 3.109 1.843 2.267 2.878 2.267 2.878s1.315.911 1.315 2.345c0 1.434-1.292 1.127-1.292 1.127s1.083.218 1.058 1.857c-.019 1.23-1.377 1.399-1.377 1.399s3.005 1.519.806 3.913c-1.065 1.159-2.617 2.622-6.325 2.007-3.708-.614-6.044-2.09-8.502-2.081-5.76.024-5.545-5.429-14.557-6.761-5.982-.883-16.695-.614-18.231-7.067-.885-3.715 2.548-17.984 2.89-24.526.201-3.848 14.127-3.808 14.668.761Z"
        style="fill:#d8d9d8;fill-rule:nonzero"
      />
      <path
        d="M212.636 465.667c.463.301 1.01.455 1.441.823.44.356.715.865.943 1.369.438 1.027.688 2.116.914 3.201l-.246.044c-.184-1.084-.391-2.172-.784-3.197-.204-.503-.454-1.009-.868-1.369-.403-.374-.949-.553-1.4-.871ZM211.077 472.536a18.821 18.821 0 0 0-.426-3.137c-.239-1.025-.566-2.058-1.206-2.913.675.83 1.046 1.861 1.327 2.883.277 1.032.454 2.085.555 3.153l-.25.014ZM204.689 467.511c.727.789 1.291 1.744 1.575 2.794.15.524.182 1.079.138 1.624-.029.553-.231 1.076-.449 1.58l-.222-.116c.224-.469.437-.958.485-1.485a4.44 4.44 0 0 0-.073-1.574c-.237-1.035-.759-2.007-1.454-2.823ZM199.936 468.374c.854.658 1.578 1.509 2.028 2.507.231.497.349 1.045.38 1.594.049.556-.088 1.104-.243 1.634l-.234-.088c.164-.495.317-1.01.29-1.541a4.237 4.237 0 0 0-.308-1.551c-.401-.99-1.085-1.865-1.913-2.555ZM265.823 435.456l-5.11-1.103 5.157.857-.047.246ZM266.142 432.199l-5.206-1.13 5.253.885-.047.245ZM267.184 430.008l-5.241-1.13 5.288.884-.047.246ZM266.087 428.717l-3.96-2.131 4.072 1.908-.112.223Z"
        class="gray-fill"
      />
      <path
        d="M262.432 204.918h-.358c.208-.895.358 0 .358 0ZM245.473 257.113c-3.963-9.442.095-13.358.095-13.358s-6.223-35.987-5.985-44.638c.335-12.14 1.492-16.056 1.492-16.056h18.237c-.224.964-.515 6.529-.759 15.008-.413 14.445-1.859 27.159.13 41.946 1.012 7.522 3.749 7.359 5.268 12.802.254.91 1.318 6.535 1.334 7.544 1.379 4.241 1.329 5.08.465 9.376-.253 1.261-4.481-.489-4.481-6.778-2.272 3.555-2.937 10.339-6.806 11.252-9.271 2.188-7.811 0-8.99-1.358-1.854-2.135-.226-4.18.631-5.89.553-1.1-1.215 2.093-2.527 1.054-2.315-1.834 3.201-7.794 1.896-10.904Z"
        style="fill:#d8d9d8;fill-rule:nonzero"
      />
      <path
        d="m253.595 198.717 7.118-4.708s-1.405-19.53-5.334-29.989c-3.929-10.459-20.443-18.513-20.443-18.513v53.21h18.659Z"
        style="fill:#154262;fill-rule:nonzero"
      />
      <path
        d="M252.475 315.771s-5.49 28.73-5.478 35.204c0 0 .464-.712-1.09 6.214 0 0-10.653 33.108-9.229 51.452 0 0-19.318 16.969-17.059 5.159 2.395-12.525 2.94-13.917.566-29.833-2.945-19.745 1.383-17.161 2.183-26.446.8-9.284-6.957-41.051-6.957-41.051"
        style="fill:#d8d9d8;fill-rule:nonzero"
      />
      <path
        d="M182.784 427.019c.47-6.99.893-12.11.893-28.202 0-20.021 5.119-17.35 5.708-26.651.589-9.3-6.43-41.819-6.43-41.819 7.885-8.889 41.762-3.951 41.762-3.951s-10.252 33.38-10.093 39.852c0 0 .395 2.144-1.002 9.104 0 0-13.907 35.659-14.84 51.217 0 0-16.27 4.5-15.998.45Z"
        style="fill:#d8d9d8;fill-rule:nonzero"
      />
      <path
        d="M228.055 145.054c3.453.721 6.679.13 9.21 2.589 5.097 4.952 7.41 8.192 8.982 12.818 2.246 6.612 7.875 36.157 7.123 49.486-.887 15.728 1.103 33.484-.371 45.735.018 4.63-42.15 24.336-69.322 9.167 0 0 1.695-26.219-2.113-50.277-2.478-15.657-7.761-26.7-6.715-39.095.247-2.921 1.944-9.889 4.392-12.097 8.342-7.528 17.961-18.388 26.526-18.388 7.164 0 18.11-.81 22.288.062Z"
        style="fill:#66b1dd;fill-rule:nonzero"
      />
      <path
        d="M183.677 264.849c-8.469 11.233-6.205 24.857-5.911 30.029.294 5.171 5.015 37.818 5.015 37.818 25.628 19.932 41.936-4.733 41.936-4.733 28.845 2.7 27.758-12.192 27.758-12.192s-.125-4.873 2.713-17.692c4.005-18.092-1.926-45.103-1.926-45.103-13.031 10.858-43.023 24.398-69.585 11.873ZM182.538 160.88s-8.665 5.256-15.667 18.091c-4.055 7.432-5.828 33.785-5.828 33.785 12.106 2.935 20.227 2.687 32.859 0 0 0 4.26-36.525 1.014-43.75-3.247-7.225-12.378-8.126-12.378-8.126Z"
        style="fill:#154262;fill-rule:nonzero"
      />
      <path
        d="M185.185 214.296s-4.502 17.478-5.866 34.723c-.682 8.628-1.632 20.798-1.632 20.798s4.895 8.712 3.636 18.341c-1.335 10.219.155 9.085-1.247 10.476-1.243 1.235-4.571-.765-3.65-4.872-.6-1.867-1.093-3.766-1.495-4.751-1.031-2.524-2.269 3.606-1.872 6.952s8.46 8.518 6.839 11.761c-.607 1.215-3.56-.044-3.56-.044s-2.511-1.985-4.133-2.441c-1.485-.418-5.084-3.458-5.084-3.458s-3.77-3.714-4.068-5.055c-2.209-9.946-1.66-10.342.921-16.44 2.76-6.522 2.65-6.366 1.769-13.905-1.257-10.755-4.194-17.835-4.639-32.279-.345-11.228-.061-21.346-.061-21.346 5.6 2.456 15.081 2.341 24.142 1.54Z"
        style="fill:#d8d9d8;fill-rule:nonzero"
      />
      <path
        d="M224.224 326.314c.856-4.864 1.547-9.764 2.179-14.672a583.724 583.724 0 0 0 1.692-14.751l.007-.066.05-.039a126.53 126.53 0 0 0 5.49-4.485 65.775 65.775 0 0 0 2.611-2.398c.834-.833 1.67-1.683 2.305-2.684-.614 1.014-1.434 1.884-2.252 2.737-.83.844-1.69 1.658-2.565 2.456a124.305 124.305 0 0 1-5.406 4.609l.057-.105c-.383 4.94-.835 9.872-1.344 14.801-.517 4.928-1.093 9.851-1.837 14.76l-.987-.163Z"
        style="fill:#231f20;fill-rule:nonzero"
      />
      <path
        d="m234.482 137.664-1.558 9.446c1.767 5.222-.761 12.089-7.575 12.089-7.625 0-13.985-3.227-17.393-5.602-5.253-3.66-7.588-7.144-7.588-7.144 4.143-16.371.343-19.006-2.143-23.557-4.256-7.791 35.771 6.984 36.257 14.768Z"
        style="fill:#d3d3d3;fill-rule:nonzero"
      />
      <clipPath id="a">
        <path
          d="m234.482 137.664-1.558 9.446c1.767 5.222-.761 12.089-7.575 12.089-7.625 0-13.985-3.227-17.393-5.602-5.253-3.66-7.588-7.144-7.588-7.144 4.143-16.371.343-19.006-2.143-23.557-4.256-7.791 35.771 6.984 36.257 14.768Z"
          clip-rule="nonzero"
        />
      </clipPath>
      <g clip-path="url(#a)">
        <path
          d="M238.238 133.254c2.125-7.88-37.727-27.393-42.604-21.238 1.402 1.309 3.982 18.201 8.622 29.904 3.252 8.201 6.559 5.595 11.939 8.164 3.382 1.615 14.58 3.832 16.729-.644 2.149-4.477 3.89-14.9 5.314-16.186Z"
          style="fill:#b4b4b4;fill-rule:nonzero"
        />
      </g>
      <path
        d="M184.396 105.422c-4.348-23.916-4.64-37.71 40.489-37.71 20.837 0 27.899-1.074 27.899 32.726 0 5.315-.836 3.949-.929 8.563-.038 1.895.166.713 2.291 7.597 1.796 5.818 3.011 16.124-3.975 20.104-.885 1.625-4.507 8.021-8.634 9.313-2.839.889-7.055 1.536-13.42-.543-2.192.539-6.315 1.722-10.802.285-5.384-1.724-7.633-2.879-11.974-14.273-6.419.542-17.651-7.943-20.945-26.062Z"
        style="fill:#d3d3d3;fill-rule:nonzero"
      />
      <path
        d="M178.214 93.321c0-32.357 31.018-35.897 44.761-34.317 11.982 1.378 21.559 8.07 24.186 11.491 3.188 4.152 4.596 12.485 4.596 12.485s-3.086-6.351-5.454-6.713c-1.202-.185-6.874 5.736-12.25 7.158-4.23 1.119-12.324-2.723-19.068-2.79-8.321-.082-15.746 5.527-18.382 10.769-.12.236-2.951 6.296 3.206 19.052 1.309 2.713 3.198 5.728 3.198 5.728s-1.772-1.704-4.214-2.103c-4.213-.689-9.373 6.866-2.971 13.268 0 0-17.608-11.645-17.608-34.028Z"
        style="fill:#154262;fill-rule:nonzero"
      />
      <path
        d="M245.722 135.067c-1.364 1.678-2.201 1.887-3.553 1.77-1.361 1.041-3.563 1.55-4.437 1.547-.879.007-2.019-1.441-2.991-1.502.36.434 1.312 2.28 2.198 2.676.535.239 1.046.432 1.389.534.913.261 1.988-1.331 3.058-1.593 1.07-.264 2.512-.055 3.425-.763.911-.716.333-1.693.911-2.669ZM241.543 110.087c4.121-4.13 9.197-4.638 9.197-4.638l2.044-.266s-6.562-.562-8.537-.148c-2.976.623-2.704 5.052-2.704 5.052Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
      />
      <path
        d="M228.142 113.96c-2.16.349-3.7.771-7.135 5.028 0 0 .825.08 1.961.155 1.722.113 4.16.216 5.515.012 2.249-.337 2.933-1.216 2.933-1.216.826-.503 1.209.44 1.209-.195 0-1.92-2.324-4.132-4.483-3.784Z"
        class="white-fill"
      />
      <clipPath id="b">
        <path
          d="M228.142 113.96c-2.16.349-3.7.771-7.135 5.028 0 0 .825.08 1.961.155 1.722.113 4.16.216 5.515.012 2.249-.337 2.933-1.216 2.933-1.216.826-.503 1.209.44 1.209-.195 0-1.92-2.324-4.132-4.483-3.784Z"
          clip-rule="nonzero"
        />
      </clipPath>
      <g clip-path="url(#b)">
        <path
          d="M224.883 117.276c0 1.388 1.088 2.339 2.43 2.123 1.341-.216 2.429-1.518 2.429-2.906 0-1.389-1.088-2.339-2.429-2.123-1.342.216-2.43 1.518-2.43 2.906Z"
          class="gray-fill"
        />
      </g>
      <path
        d="M228.142 113.96c-2.16.349-3.7.771-7.135 5.028 0 0 .825.08 1.961.155 1.722.113 4.16.216 5.515.012 2.249-.337 2.933-1.216 2.933-1.216.826-.503 1.209.44 1.209-.195 0-1.92-2.324-4.132-4.483-3.784Z"
        style="fill:none;stroke:#7c7c7c;stroke-width:.28px"
      />
      <path
        d="M230.955 114.275c1.97 1.222 1.61 2.99 1.61 2.99s-.673-2.943-3.58-2.684c-4.543.403-7.289 3.433-7.978 4.407l-.637-1.15s6.655-5.999 10.585-3.563Z"
        class="gray-fill"
      />
      <path
        d="M245.531 111.157c1.732-.279 3.334-.291 4.64 3.129 0 0-2.142 1.718-4.692 2.129-.751.122-1.179.064-1.995-.258-.663-.262-1.103.278-1.145-.348-.183-2.711 1.12-4.318 3.192-4.652Z"
        class="white-fill"
      />
      <clipPath id="c">
        <path
          d="M245.531 111.157c1.732-.279 3.334-.291 4.64 3.129 0 0-2.142 1.718-4.692 2.129-.751.122-1.179.064-1.995-.258-.663-.262-1.103.278-1.145-.348-.183-2.711 1.12-4.318 3.192-4.652Z"
          clip-rule="nonzero"
        />
      </clipPath>
      <g clip-path="url(#c)">
        <path
          d="M247.629 113.581c0 1.287-.97 2.486-2.167 2.679-1.196.193-2.166-.694-2.166-1.98s.97-2.485 2.166-2.678c1.197-.192 2.167.694 2.167 1.979Z"
          class="gray-fill"
        />
      </g>
      <path
        d="M245.531 111.157c1.732-.279 3.334-.291 4.64 3.129 0 0-2.142 1.718-4.692 2.129-.751.122-1.179.064-1.995-.258-.663-.262-1.103.278-1.145-.348-.183-2.711 1.12-4.318 3.192-4.652Z"
        style="fill:none;stroke:#7c7c7c;stroke-width:.25px"
      />
      <path
        d="M243.273 112.289c-1.581 1.795-1.021 2.557-1.021 2.557s.722-2.549 3.053-3.172c2.033-.544 4.313 1.838 4.866 2.612l.569-1.344s-4.313-4.231-7.467-.653Z"
        class="gray-fill"
      />
      <path
        d="M248.035 127.786s-.349 1.456-2.139 1.666c-1.789.21-5.081.058-7.444 1.203-2.363 1.145-3.023.328-3.349-.094-.842-1.088 1.112-4.664 1.112-4.664s-.736 3.331.803 3.665c1.179.256 3.46-1.171 5.138-1.918 1.079-.48 2.508-1.196 3.589-1.071l2.29 1.213ZM222.589 110.124c2.416-1.516 9.334-3.121 11.672-3.484 4.537-.704 2.587 11.801 2.587 11.801s-1.031-4.514-1.382-5.9c-.574-2.272-1.662-3.083-4.251-2.72-2.588.363-12.246 3.765-12.246 3.765s1.219-1.954 3.62-3.462Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
      />
      <path
        d="M233.021 132.428a467.851 467.851 0 0 0 1.919 2.191s5.67-1.736 6.111-1.971l1.709.102 1.483-.939s.606.099 1.902.068c.628-.015.221-3.117.102-3.288.105.15 1.798.43 1.788 1.757-.01 1.327-.883 3.319-1.526 5.157-.132.037.188-2.533-.364-2.649-.552-.116-1.365.77-1.774.949-.742.324-1.671.838-2.87 1.167-5.518 1.513-6.357.715-7.318.605l-3.087 4.544c.97-3.135 2.617-5.758 1.925-7.693ZM194.916 121.348s-.025-3.849 2.425-3.69c1.049.069 3.607.515 4.693 3.248 1.377 3.465-1.729 2.199-1.729 3.996 0 1.796-.423 1.382 1.315 3.689 0 0-3.907-2.121-4.182-3.427-.434-2.059 4.383-1.185 1.458-4.316-1.743-1.867-3.98.5-3.98.5Z"
        style="fill:#b4b4b4;fill-rule:nonzero"
      />
    </g>
  </svg>
</template>
<script setup>
  import { getRaceCode } from "@/utils/GlobalState"

  const race = getRaceCode()
  defineProps({
    showArrows: {
      type: Boolean,
      default: false,
    }
  })
</script>
