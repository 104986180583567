<script setup>
  import WeightAdultChildExample from "@/components/svg/WeightAdultChildExample.vue"
  import { setCurrentViewContext } from "@/utils/GlobalState"

  setCurrentViewContext(null, "library")
</script>
<template>
  <article class="library-item">
    <h1 class="title">
      {{ $gettext("How to measure weight") }}
    </h1>
    <h2 class="subtitle">
      {{ $gettext("Children 2 years and older who are unable to stand") }}
    </h2>
    <div class="columns mb-5 mt-5">
      <section class="column">
        <div class="block columns is-mobile">
          <div class="column">
            <h2>{{ $gettext("You Need") }}</h2>
            <ul>
              <li>{{ $gettext("A standing scale") }}</li>
              <li>{{ $gettext("An assistant") }}</li>
            </ul>
          </div>
          <div class="column">
            <WeightAdultChildExample />
          </div>
        </div>
  
        <div class="block">
          <h2>{{ $gettext("Follow these steps") }}</h2>
    
          <ol>
            <li>{{ $gettext("Place scale on a flat, hard surface.") }} </li>
            <li>{{ $gettext("Turn the scale on with no load on the scale.") }} </li>
            <li>{{ $gettext("Make sure the scale is set to kg.") }} </li>
            <li>{{ $gettext("Make sure the scale reads zero before using.") }} </li>
            <li>{{ $gettext("Remove the child’s shoes and heavy clothes.") }} </li>
            <li>{{ $gettext("Ask the assistant to stand centered on the scale.") }} </li>
            <li>{{ $gettext("Set the scale to zero by pressing the “Tare” or “Zero” button on the scale.") }} </li>
            <li>{{ $gettext("Hand the child to the assistant to carry.") }} </li>
            <li>{{ $gettext("Read the weight off the display and record to the nearest 0.01 kg (e.g. 14.22 kg).") }} </li>
          </ol>
        </div>
      </section>
      <div class="column">
        <aside class="card mb-6">
          <div class="card-content">
            <h2>{{ $gettext("Tips") }}</h2>
            <ul>
              <li>{{ $gettext("If a baby scale is not available, use a standing scale and follow the steps for older children who are unable to stand.") }} </li>
              <li>{{ $gettext("Do not touch the child or assistant while weighing.") }}</li>
              <li>{{ $gettext("Make sure the assistant and the child are not touching anything around them.") }}</li>
              <li>{{ $gettext("If the “Tare” function is not available on the standing scale, calculate the child’s weight by subtracting the weight of the assistant from the weight of the assistant with child: Child’s weight = weight of assistant with child – weight of assistant.") }}</li>
              <li>{{ $gettext("Children older than 2 years who are small in size and unable to stand can be weighed using a baby scale.") }}</li>
            </ul>
          </div>
        </aside>
        <div class="message is-spoon-blue">
          <div class="message-header">
            <p style="margin-bottom: 0">
              {{ $gettext("When measuring children, always:") }}
            </p>
          </div>
          <div class="message-body">
            <ul>
              <li>{{ $gettext("Sanitize your hands before and afterwards.") }}</li>
              <li>{{ $gettext("Sanitize equipment after use.") }}</li>
              <li>{{ $gettext("Store equipment in a dry place.") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
