<script setup>
  import WeightChildExample from "@/components/svg/WeightChildExample.vue"
  import { setCurrentViewContext } from "@/utils/GlobalState"

  setCurrentViewContext(null, "library")
</script>
<template>
  <article class="library-item">
    <h1 class="title">
      {{ $gettext("How to measure weight") }}
    </h1>
    <h2 class="subtitle">
      {{ $gettext("Children 2 years and older who are able to stand") }}
    </h2>
    <div class="columns mb-5 mt-5">
      <section class="column">
        <div class="block columns is-mobile">
          <div class="column">
            <h2>{{ $gettext("You Need") }}</h2>
            <ul>
              <li>{{ $gettext("A standing scale") }}</li>
            </ul>
          </div>
          <div class="column">
            <WeightChildExample />
          </div>
        </div>
  
        <div class="block">
          <h2>{{ $gettext("Follow these steps") }}</h2>
    
          <ol>
            <li>{{ $gettext("Place scale on a flat, hard surface.") }}</li>
            <li>{{ $gettext("Turn the scale on.") }}</li>
            <li>{{ $gettext("Make sure the scale is set to kg.") }}</li>
            <li>{{ $gettext("Make sure the scale reads zero before using.") }}</li>
            <li>{{ $gettext("Remove child’s shoes and heavy clothes.") }}</li>
            <li>{{ $gettext("Ask the child to stand in the center of the scale.") }}</li>
            <li>{{ $gettext("Read weight off the display and record to the nearest 0.01 kg (e.g. 14.22 kg).") }}</li>
          </ol>
        </div>
      </section>
      <div class="column">
        <aside class="card mb-6">
          <div class="card-content">
            <h2>{{ $gettext("Tips") }}</h2>
            <ul>
              <li>{{ $gettext("Make sure child is not touching anything around him.") }}</li>
              <li>{{ $gettext("Do not touch the child while weighing.") }}</li>
              <li>{{ $gettext("Child should be able to stand without support.") }}</li>
            </ul>
          </div>
        </aside>
        <div class="message is-spoon-blue">
          <div class="message-header">
            <p style="margin-bottom: 0">
              {{ $gettext("When measuring children, always:") }}
            </p>
          </div>
          <div class="message-body">
            <ul>
              <li>{{ $gettext("Sanitize your hands before and afterwards.") }}</li>
              <li>{{ $gettext("Sanitize equipment after use.") }}</li>
              <li>{{ $gettext("Store equipment in a dry place.") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
