<script setup>
import { ref } from "vue"
import { MeasurementType } from "@/utils/GrowthAssessment"
import { isChildOlderThan2 } from "@/services/GrowthAssessment"
import { getWeightSchema } from "@/schemas/GrowthAssessment"
import GrowthMeasurementForm from "@/components/assessments/GrowthMeasurementForm.vue"
import WeightAdultChildExample from "@/components/svg/WeightAdultChildExample.vue"
import WeightChildExample from "@/components/svg/WeightChildExample.vue"
import WeightInfantExample from "@/components/svg/WeightInfantExample.vue"
import MeasureWeightInfant from "@/views/library/MeasureWeightInfant.vue"
import MeasureWeightAdultChild from "@/views/library/MeasureWeightAdultChild.vue"
import MeasureWeightChild from "@/views/library/MeasureWeightChild.vue"

const weightImage = ref("")

function extraGetDataFunc(growthAssessment) {
  if (isChildOlderThan2(growthAssessment.child.dob, growthAssessment.dateOfAssessment)) {
    weightImage.value = growthAssessment.canChildStand ? "standing" : "adult"
  }
  else {
    weightImage.value = "infant"
  }
}

</script>

<template>
  <GrowthMeasurementForm
    :get-schema-func="getWeightSchema"
    :extra-get-data-func="extraGetDataFunc"
    :extract-value-func="data => parseFloat(data.weightInKilograms) || null"
    :get-measurement-type-func="() => MeasurementType.Weight"
    :get-update-data-func="data => { return { weightInKilograms: parseFloat(data.weightInKilograms) || null }}"
  >
    <template #modalContent>
      <MeasureWeightInfant v-if="weightImage == 'infant'" />
      <MeasureWeightAdultChild v-if="weightImage == 'adult'" />
      <MeasureWeightChild v-if="weightImage == 'standing'" />
    </template>
    <template #exampleImage>
      <div v-if="weightImage">
        <div :class="{'measurement-example': true, tall: weightImage !== 'infant'}">
          <WeightAdultChildExample v-if="weightImage === 'adult'" />
          <WeightChildExample v-if="weightImage === 'standing'" />
          <WeightInfantExample v-if="weightImage === 'infant'" />
        </div>
      </div>
    </template>
  </GrowthMeasurementForm>
</template>

<style scoped>
  .measurement-example {
    min-height: 3em;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .measurement-example.tall {
    max-width: 25%;
  }
  @media (min-width: 626px) {
  .measurement-example.tall {
    max-width: 15%;
    }
  }
</style>
