<script setup>
  import WeightInfantExample from "@/components/svg/WeightInfantExample.vue"
  import { setCurrentViewContext } from "@/utils/GlobalState"

  setCurrentViewContext(null, "library")
</script>
<template>
  <article class="library-item">
    <h1 class="title">
      {{ $gettext("How to measure weight") }}
    </h1>
    <h2 class="subtitle">
      {{ $gettext("Children younger than 2 years") }}
    </h2>
    <div class="columns mb-5 mt-5">
      <section class="column">
        <div class="block columns is-mobile">
          <div class="column">
            <h2>{{ $gettext("You Need") }}</h2>
            <ul>
              <li>{{ $gettext("A baby scale") }}</li>
            </ul>
          </div>
          <div class="column">
            <WeightInfantExample />
          </div>
        </div>
  
        <div class="block">
          <h2>{{ $gettext("Follow these steps") }}</h2>
    
          <ol>
            <li>{{ $gettext("Place scale on a flat, hard surface.") }}</li>
            <li>{{ $gettext("Turn the scale on.") }}</li>
            <li>{{ $gettext("Make sure the scale is set to kg.") }}</li>
            <li>{{ $gettext("Make sure the scale reads zero before using.") }}</li>
            <li>{{ $gettext("Remove child’s clothing and, if appropriate, the diaper.") }}</li>
            <li>{{ $gettext("Lay the child centered on the scale.") }}</li>
            <li>{{ $gettext("Read weight off the display and record to the nearest 0.01 kg (e.g. 4.55 kg).") }}</li>
          </ol>
        </div>
      </section>
      <aside class="column card">
        <div class="card-content">
          <h2>{{ $gettext("Tips") }}</h2>
          <ul>
            <li>{{ $gettext("If a baby scale is not available, use a standing scale and follow the steps for older children who are unable to stand.") }} </li>
            <li>{{ $gettext("To keep scale clean while making baby more comfortable, place a soft towel or smooth disposable paper on the scale before you turn the scale on.") }} </li>
            <li>{{ $gettext("Make sure child is not touching anything around him.") }} </li>
            <li>{{ $gettext("Do not touch the child while weighing.") }} </li>
            <li>{{ $gettext("If diaper is not removed, make sure it is dry.") }} </li>
            <li>{{ $gettext("Do not weigh children right after feeding.") }} </li>
          </ul>
        </div>
      </aside>
    </div>
    <div class="columns">
      <div class="column">
        <div class="message is-spoon-blue">
          <div class="message-header">
            <p style="margin-bottom: 0">
              {{ $gettext("When measuring children, always:") }}
            </p>
          </div>
          <div class="message-body">
            <ul>
              <li>{{ $gettext("Sanitize your hands before and afterwards.") }}</li>
              <li>{{ $gettext("Sanitize equipment after use.") }}</li>
              <li>{{ $gettext("Store equipment in a dry place.") }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
